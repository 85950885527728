import "./utils/dayjsloader";
import { TagType } from "./components/changelog/TagType";
import dayjs from "dayjs";

const djs = dayjs as any

const versions = [
    { id: "35", code: "0.11.1", release: djs({ year: 2024, month: 7, day: 15 }), tags: [TagType.FEATURES], major: false },
    { id: "34", code: "0.11.0", release: djs({ year: 2022, month: 11, day: 31 }), tags: [TagType.FEATURES], major: true },
    { id: "33", code: "0.10.5", release: djs({ year: 2022, month: 3, day: 5 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "32", code: "0.10.4", release: djs({ year: 2022, month: 2, day: 24 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "31", code: "0.10.3", release: djs({ year: 2022, month: 2, day: 13 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "30", code: "0.10.2", release: djs({ year: 2022, month: 2, day: 10 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "29", code: "0.10.1", release: djs({ year: 2022, month: 2, day: 3 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "28", code: "0.10.0", release: djs({ year: 2022, month: 0, day: 30 }), tags: [TagType.ROLES], major: true },
    { id: "27", code: "0.9.0", release: djs({ year: 2021, month: 11, day: 31 }), tags: [TagType.FEATURES, TagType.IMPROVEMENTS], major: true },
    { id: "26", code: "0.8.0", release: djs({ year: 2021, month: 10, day: 27 }), tags: [TagType.ROLES, TagType.IMPROVEMENTS, TagType.BUGFIXES], major: true },
    { id: "25", code: "0.7.4", release: djs({ year: 2021, month: 7, day: 3 }), tags: [TagType.BUGFIXES], major: false },
    { id: "24", code: "0.7.3", release: djs({ year: 2021, month: 4, day: 24 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "23", code: "0.7.2", release: djs({ year: 2021, month: 4, day: 19 }), tags: [TagType.BUGFIXES], major: false },
    { id: "22", code: "0.7.1", release: djs({ year: 2021, month: 4, day: 7 }), tags: [TagType.BUGFIXES], major: false },
    { id: "21", code: "0.7.0", release: djs({ year: 2021, month: 3, day: 16 }), tags: [TagType.FEATURES, TagType.IMPROVEMENTS], major: true },
    { id: "20", code: "0.6.12", release: djs({ year: 2021, month: 2, day: 29 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "19", code: "0.6.11", release: djs({ year: 2021, month: 2, day: 11 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "18", code: "0.6.10", release: djs({ year: 2021, month: 2, day: 8 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "17", code: "0.6.9", release: djs({ year: 2021, month: 2, day: 7 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "16", code: "0.6.8", release: djs({ year: 2021, month: 2, day: 6 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "15", code: "0.6.7", release: djs({ year: 2021, month: 2, day: 4 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "14", code: "0.6.6", release: djs({ year: 2021, month: 1, day: 18 }), tags: [TagType.FEATURES, TagType.BUGFIXES], major: false },
    { id: "13", code: "0.6.5", release: djs({ year: 2021, month: 0, day: 27 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "12", code: "0.6.4", release: djs({ year: 2021, month: 0, day: 14 }), tags: [TagType.FEATURES, TagType.BUGFIXES], major: false },
    { id: "11", code: "0.6.3", release: djs({ year: 2021, month: 0, day: 13 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "10", code: "0.6.2", release: djs({ year: 2021, month: 0, day: 12 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "9", code: "0.6.1", release: djs({ year: 2021, month: 0, day: 11 }), tags: [TagType.IMPROVEMENTS], major: false },
    { id: "8", code: "0.6.0", release: djs({ year: 2021, month: 0, day: 11 }), tags: [TagType.FEATURES, TagType.IMPROVEMENTS, TagType.BUGFIXES], major: true },
    { id: "7", code: "0.5.2", release: djs({ year: 2021, month: 0, day: 2 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "6", code: "0.5.1", release: djs({ year: 2021, month: 0, day: 1 }), tags: [TagType.IMPROVEMENTS, TagType.BUGFIXES], major: false },
    { id: "5", code: "0.5.0", release: djs({ year: 2020, month: 11, day: 31 }), tags: [TagType.FEATURES, TagType.IMPROVEMENTS, TagType.BUGFIXES], major: true },
    { id: "4", code: "0.4", release: djs({ year: 2020, month: 10, day: 27 }), tags: [TagType.FEATURES, TagType.IMPROVEMENTS, TagType.BUGFIXES], major: true },
    { id: "3", code: "0.3", release: djs({ year: 2020, month: 9, day: 11 }), tags: [TagType.FEATURES, TagType.ROLES, TagType.IMPROVEMENTS], major: true },
    { id: "2", code: "0.2", release: djs({ year: 2020, month: 8, day: 25 }), tags: [TagType.FEATURES, TagType.BUGFIXES], major: true },
    { id: "1", code: "0.1", release: djs({ year: 2020, month: 8, day: 3 }), tags: [TagType.FEATURES], major: true },
    { id: "0", code: "0", release: djs({ year: 2020, month: 7, day: 26 }), tags: [], major: true }
]

export default versions;