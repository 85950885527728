import { Typography } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import ChangelogTagsTag from "./ChangelogTagsTag";
import { TagType } from "./TagType";

interface Props {
    tags: TagType[]
}

export default function ChangelogTags(props: Props) {
    const { tags } = props;

    const { t } = useTranslation();

    return <>
        {tags.length > 0 && <Typography variant="body2" color="textSecondary">{t(`changelog:tags.tags`)}</Typography>}
        {tags.map(tag => <ChangelogTagsTag key={tag} tagType={tag}/>)}
    </>
}