import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    categoryContainer: {
        display: "flex",
        flex: "1 1 180px",
        justifyContent: "space-around"
    },
    footerList: {
        listStyle: "none",
        padding: 0
    },
    category: {
        fontWeight: "700",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center"
        }
    },
    entry: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            textAlign: "center"
        }
    }
}));

export default function FooterCategory(props) {
    const { categoryName, entries } = props;

    const classes = useStyles();

    return <div className={classes.categoryContainer}>
        <div>
            <p className={classes.category}>{categoryName}</p>
            <ul className={classes.footerList}>
                {entries.map(entry => {
                    return <li className={classes.entry} key={entry.key}>{entry.component}</li>
                })}
            </ul>
        </div>
    </div>
}