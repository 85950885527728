import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from "next-translate/useTranslation";
import { TagType } from "./TagType";

interface Props {
    tagType: TagType
}

const useStyles = makeStyles({
    tag: {
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap"
    },
    text: {
        color: "white",
        fontWeight: 500
    }
});

export default function ChangelogTagsTag(props: Props) {
    const { tagType } = props;

    const { t } = useTranslation();

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Tooltip title={t(`changelog:tags.${tagType}.description`)} placement="top" arrow>
            <Box key={tagType} px="4px" py="1px" bgcolor={tagType === TagType.FEATURES ? theme.palette.success.light : tagType === TagType.ROLES ? theme.palette.warning.light : tagType === TagType.IMPROVEMENTS ? theme.palette.info.light : tagType === TagType.BUGFIXES ? theme.palette.error.light : theme.palette.grey[300]} borderRadius="3px" ml={0.7} className={classes.tag}>
                <Typography className={classes.text} variant="caption" component="p">{t(`changelog:tags.${tagType}.title`)}</Typography>
            </Box>
        </Tooltip>
    );
}