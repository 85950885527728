import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";

const useStyles = makeStyles(theme => ({
    heading: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row-reverse"
    },
    titleContainer: {
        display: "flex",
        alignSelf: "center",
        padding: 16,
        [theme.breakpoints.down('lg')]: {
            flexWrap: "wrap"
        }
    },
    title: {
        fontWeight: "700",
        lineHeight: "1",
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            textAlign: "center"
        }
    },
    subtitle: {
        fontWeight: "500",
        lineHeight: "1",
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            marginTop: -8,
        },
        [theme.breakpoints.down('md')]: {
            textAlign: "center"
        }
    },
    logoContainer: {
        display: "flex",
        margin: "16px 32px"
    },
    logo: {
        cursor: "pointer",
        width: "100%"
    }
}));

const utm = "?utm_source=wwo&utm_medium=header";

const openWebsite = () => {
    window.open(`https://nextglobe.net${utm}`, '_blank');
}

export default function Header(props) {
    const { confirm } = props;

    const { t } = useTranslation();

    const router = useRouter();

    const classes = useStyles();

    return <div className={classes.heading}>
        <div className={classes.logoContainer}>
            <Image src="/Logo_Fit.svg" alt="NextGlobe logo" width="171" height="150" priority className={classes.logo} onClick={openWebsite} />
        </div>
        <div className={classes.titleContainer}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography variant="h1" className={classes.title}><a href={`${process.browser ? window.location.protocol + "//" + window.location.host : "https://werewolf-online.io"}${utm}`} onClick={(e) => {
                    e.preventDefault();
                    if (!confirm || window.confirm("Do you really want to leave the game?")) {
                        router.push("/");
                    }
                }}>{t("header:title")}</a></Typography>
                <Typography variant="h4" component="h3" className={classes.subtitle}><a href={`https://nextglobe.net${utm}`} target="_blank" rel="noreferrer">{t("header:subtitle")}</a></Typography>
            </Box>
            <Box alignSelf="flex-start" sx={{ display: "flex", width: { xs: "100%", md: "auto" }, justifyContent: "center", marginTop: { "xs": 2, "md": 0 } }}>
                <Box sx={{ userSelect: "none", backgroundColor: "#F15A26", borderRadius: "4px", py: "1px", px: "4px", marginLeft: { "sm": 1 }, marginRight: { "sm": 0 }, marginTop: { "sm": 0 } }}>
                    <Typography variant="body2" style={{ color: "#fff" }}>public alpha</Typography>
                </Box>
            </Box>
        </div>
    </div>
}