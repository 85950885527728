import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import useTranslation from "next-translate/useTranslation";
import SquareFontAwesomeIcon from "../util/SquareFontAwesomeIcon";

interface Props {
    releaseDate: Dayjs
}

export default function ChangelogReleaseDate(props: Props) {
    const { releaseDate } = props;

    const { lang } = useTranslation();

    return <>
        <SquareFontAwesomeIcon icon={faCalendarAlt} sx={{
            color: theme => theme.palette.text.secondary,
            width: "0.875em !important",
            height: "0.875em",
            marginRight: 0.5
        }} />
        <Typography variant="body2" color="textSecondary">{releaseDate.locale(lang).format("L")}</Typography>
    </>
}