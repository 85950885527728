/* eslint-disable no-undef */
import { Link, Typography, Box, useTheme } from "@mui/material";
import FooterCategory from "./FooterCategory";
import useTranslation from "next-translate/useTranslation";

const utm = "?utm_source=wwo&utm_medium=footer"

export default function Footer() {
    const { t } = useTranslation();

    const theme = useTheme();

    const legalEntries = [
        { key: "imprint", component: <Link href={`https://nextglobe.net/imprint${utm}`} target="_blank" rel="noopener" underline="hover">{t("footer:imprint")}</Link> },
        { key: "privacy-policy", component: <Link href={`https://nextglobe.net/privacy-policy${utm}`} target="_blank" rel="noopener" underline="hover">{t("footer:privacy-policy")}</Link> }
    ]
    const socialMediaEntries = [
        { key: "discord", component: <Link href={`https://nextglo.be/discord${utm}`} target="_blank" rel="noopener" underline="hover">Discord</Link> },
        { key: "facebook", component: <Link href={`https://facebook.com/NextGlobeNet${utm}`} target="_blank" rel="noopener" underline="hover">Facebook</Link> },
        { key: "twitter", component: <Link href={`https://twitter.com/NextGlobeNet${utm}`} target="_blank" rel="noopener" underline="hover">Twitter</Link> },
        { key: "twitch", component: <Link href={`https://twitch.tv/NextGlobeNet${utm}`} target="_blank" rel="noopener" underline="hover">Twitch</Link> }
    ]
    const otherGamesEntries = [
        { key: "coming-soon", component: <Typography color="primary">Coming soon</Typography> }
    ]

    return <Box component="footer" sx={{ display: "flex", flexWrap: "wrap", backgroundColor: theme.palette.mode === "dark" ? "#121417" : "#F6F6F6", padding: "8px" }}>
        <FooterCategory categoryName={t("footer:legal")} entries={legalEntries} />
        <FooterCategory categoryName={t("footer:socialmedia")} entries={socialMediaEntries} />
        <FooterCategory categoryName={t("footer:othergames")} entries={otherGamesEntries} />
        <Box display="flex" width="100%" flexWrap="wrap">
            <Box display="flex" alignItems="flex-end">
                <Typography variant="body2" color="textSecondary" align="center">Consider <Link href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JFZ6SR39SRFQ2&source=url" rel="noopener" target="_blank">donating via PayPal</Link> in order to support further development</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end" flexGrow={1}>
                <Typography variant="body2" color="textSecondary">public alpha – v{process.env.version}</Typography>
                <Typography variant="body2">© NextGlobe 2023</Typography>
            </Box>
        </Box>
    </Box>
}