import Head from "next/head";
import useTranslation from 'next-translate/useTranslation';

const url = "https://werewolf-online.io"

export default function LanguageAwareHead() {
    const { t, lang } = useTranslation();

    const baseUrl = (process.browser && (window.location.protocol + "//" + window.location.host)) || url;

    const title = t("social:default.title");
    const seoTitle = t("social:default.seoTitle", { defaultTitle: title });
    const description = t("social:default.description");

    return <Head>
        <title>{seoTitle}</title>
        <meta name="description" content={description} />
        <meta name="twitter:image" content={`${baseUrl}/og/${lang}.png`} />
        <meta name="og:title" content={seoTitle} />
        <meta name="og:site_name" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:image" content={`${baseUrl}/og/${lang}.png`} />
    </Head>;
}
