import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import calendar from "dayjs/plugin/calendar";
// Locales (should not be loaded on start but it's a workaround for now) (maybe TODO)
import 'dayjs/locale/tr';
import 'dayjs/locale/de';

dayjs.extend(objectSupport);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(calendar);